import React from 'react';
import foto05 from '../images/foto05.jpg';
import fotoExclusiva from '../images/exclusivo.png';
import iconeEficacia from '../images/eficacia.png'
import iconeInteligente from '../images/inteligente.png'
import iconeCompleta from '../images/solucao.png'

import Viewer from 'react-viewer';
import { useState } from 'react'
import {Helmet} from "react-helmet";
import Rodape from "./Rodape";

const Content = () => {

    const [visible, setVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const openViewer = (index) => {
        setActiveIndex(index);
        setVisible(true);
    };

    const images = [
        { src: '../images/foto01.jpg', alt: 'foto 1' },
        { src: '../images/foto02.jpg', alt: 'foto 2' },
        { src: '../images/foto03.jpg', alt: 'foto 3' },
        { src: '../images/foto04.jpg', alt: 'foto 4' },
        { src: '../images/foto05.jpg', alt: 'foto 5' },
        { src: '../images/foto06.jpg', alt: 'foto 6' }
    ];

    return (
        <div className=''>
            <Helmet>
                <title>Home - Protege Piso</title>
                <meta name="description" content="A Protege Piso é um sistema altamente eficaz e de fácil instalação
                criado para proteção de pisos com grande resistência a impacto e intempéries climáticas." />

                {/* Open Graph */}
                <meta property="og:title" content="Home - Protege Piso" />
                <meta property="og:description" content="A Protege Piso é um sistema altamente eficaz e de fácil instalação
                criado para proteção de pisos com grande resistência a impacto e intempéries climáticas." />
                <meta property="og:image" content="hhttps://protegepiso.com.br/wp-content/uploads/elementor/thumbs/logo-contorno-final-3-qr9ktubyh3wykeoagoppheagkansuuyyyeqvz95qtk.png" />
                <meta property="og:url" content="https://protegepiso.com.br/franqueado" />
                <meta property="og:type" content="article" />

                {/* Canonical Tag */}
                <link rel="canonical" href="https://protegepiso.com.br/franqueado" />
            </Helmet>
            <div className="text-center ml-8 mr-8">
                <h1 className="text-2xl md:text-3xl font-bold mt-12 mb-8 md:mt-20 md:mb-16">
                    UM SISTEMA INOVADOR PARA PROTEÇÃO DE PISOS
                </h1>
                <div className="flex flex-col md:flex-row items-center justify-center gap-6">
                    <img
                        src={foto05}
                        alt="Protege Piso"
                        className="w-full md:w-2/6 max-h-80 md:max-h-96 object-cover rounded-lg"
                    />
                    <div className="text-left max-w-full md:max-w-lg">
                        <h3 className="text-lg md:text-xl font-semibold mb-4">
                            A Protege Piso é um sistema altamente eficaz e de fácil instalação
                            criado para proteção de pisos com grande resistência a impacto e intempéries climáticas.
                        </h3>
                        <p className="mb-4">
                            A Protege Piso é um sistema altamente eficaz e de fácil instalação criado para proteção de
                            pisos, com grande resistência a impacto e intempéries climáticas.
                        </p>
                        <p className="mb-4">
                            O produto foi desenvolvido com muito orgulho a partir do poder de inovação e tecnologia
                            brasileira.
                        </p>
                        <p className="mb-4">
                            A Protege Piso tem eficácia comprovada na prevenção de acidentes com o piso instalado, além
                            de manter a obra organizada e limpa por ter um
                            revolucionário sistema de sulcos que minimiza o acúmulo de resíduos durante a construção ou
                            reforma.
                        </p>
                        <p>
                            Não corra o risco de aumentar o custo de sua obra com a troca de pisos danificados ou de mão
                            de obra de reinstalação, contrate a Protege Piso.
                        </p>
                    </div>
                </div>
            </div>

            <div
                className="w-3/4 bg-green-900 text-white flex items-center justify-center h-24 md:h-32 uppercase mt-12 md:mt-52 mx-auto"
                id="payuse">
                <h3 className="text-lg md:text-xl font-bold text-green-500 tracking-widest">
                    DIFERENCIAIS DA PROTEÇÃO PARA PISO
                </h3>
            </div>

            <div className="flex flex-col items-center mt-12 md:mt-28">
                <img
                    src={fotoExclusiva}
                    alt="Protege Piso"
                    className="w-3/4 md:w-2/4"
                />
                <h3 className="text-xl md:text-3xl font-semibold mt-12 md:mt-20 text-center">
                    Você não compra a proteção de piso, simplesmente aluga.
                </h3>
                <p className="text-center mt-4 max-w-md md:max-w-lg">
                    E o mais interessante, loca pelo prazo que preferir e por apenas a quantidade de metros quadrados
                    necessária para proteger o seu piso. Mais economia para sua obra.
                </p>
            </div>

            <div className="flex flex-col items-center mt-12 md:mt-20 " id="diferencial">
                <h3 className="text-xl md:text-3xl font-semibold mb-12 md:mb-20 uppercase text-center ">
                    Inovador e inteligente
                </h3>

                <div className='flex flex-col md:flex-row justify-center gap-6 md:gap-12'>
                    <div className="flex flex-col items-center text-center">
                        <img
                            src={iconeEficacia}
                            alt="Protege Piso"
                            className="w-3/4 md:w-1/4"
                        />
                        <h3 className="text-xl md:text-3xl font-semibold mt-6 md:mt-8">
                            Eficácia comprovada
                        </h3>
                        <p className="mt-4 max-w-md md:max-w-lg">
                            Alta resistência a impacto;
                            Excepcional resistência a abrasão, fluxo de pessoas, andaimes, carriolas e trânsito de
                            empilhadeiras;
                        </p>
                        <p className="mt-4 max-w-md md:max-w-lg">
                            Excelente performance em vedação para líquidos e sólidos, como tintas, cimento, argamassa e
                            massa de revestimento.
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-center">
                        <img
                            src={iconeInteligente}
                            alt="Protege Piso"
                            className="w-3/4 md:w-1/4"
                        />
                        <h3 className="text-xl md:text-3xl font-semibold mt-6 md:mt-8">
                            Instalação inteligente
                        </h3>
                        <p className="mt-4 max-w-md md:max-w-lg">
                            Sistema modular de montagem, impossibilitando o encaixe incorreto das peças;
                        </p>
                        <p className="mt-4 max-w-md md:max-w-lg">
                            Não utiliza adesivo, a fixação ocorre pelo peso da peça e pela geometria intertravada.
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-center">
                        <img
                            src={iconeCompleta}
                            alt="Protege Piso"
                            className="w-3/4 md:w-1/4"
                        />
                        <h3 className="text-xl md:text-3xl font-semibold mt-6 md:mt-8">
                            Solução completa
                        </h3>
                        <p className="mt-4 max-w-md md:max-w-lg">
                            O Transporte, mão de obra, instalação, manutenção e retirada das placas é totalmente
                            executado pela Protege Piso sem custos extras.
                        </p>
                        <p className="mt-4 max-w-md md:max-w-lg">
                            A proteção de seu piso é nossa responsabilidade.
                        </p>
                    </div>
                </div>

                <div className='mt-12 md:mt-20 mb-12 md:mb-16' id="videos">
                    <h3 className="text-xl md:text-3xl font-semibold mb-8 md:mb-20 uppercase text-center">
                        Vídeo institucional
                    </h3>
                    <div className="w-full flex justify-center">
                        <iframe
                            className="w-full h-auto sm:w-[500px] sm:h-[281px] md:w-[600px] md:h-[338px] lg:w-[700px] lg:h-[394px] xl:w-[800px] xl:h-[450px]"
                            src="https://www.youtube.com/embed/6VydtOBK_qQ"
                            title="Vídeo Institucional - Protege Piso"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        />
                    </div>
                </div>


                <div className='mt-12 md:mt-20 mb-12 md:mb-16 ml-16 mr-16' id="fotos">
                    <h3 className="text-xl md:text-3xl font-semibold mb-8 md:mb-20 uppercase text-center">
                        CONFIRA EM DETALHES A PROTEÇÃO DE PISO.
                    </h3>

                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {images.map((image, index) => (
                            <div key={index} className="flex justify-center">
                                <img
                                    src={image.src}
                                    alt={image.alt}
                                    className="cursor-pointer w-full h-auto"
                                    onClick={() => openViewer(index)}
                                />
                            </div>
                        ))}
                    </div>

                    <Viewer
                        visible={visible}
                        onClose={() => setVisible(false)}
                        images={images}
                        activeIndex={activeIndex}
                    />
                </div>
            </div>
            <Rodape/>
        </div>
    );
};

export default Content;

