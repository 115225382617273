import React from 'react';
import BannerHome from "./components/BannerHome";
import Content from "./components/Content";

function Home(props) {
    return (
        <div>
            <BannerHome/>
            <Content/>
        </div>
    );
}

export default Home;