import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie'; // Importa a biblioteca de cookies

class CookieComponent extends Component {
    constructor(props) {
        super(props);
        // Verifica se o cookie 'cookieConsent' já está definido
        this.state = {
            isVisible: !Cookies.get('cookieConsent'), // Exibe a mensagem apenas se o cookie não existir
        };
    }

    // Função para ocultar a mensagem de cookie e armazenar a decisão em um cookie
    handleClose = () => {
        this.setState({ isVisible: false });
        Cookies.set('cookieConsent', 'true', { expires: 7 }); // Armazena o cookie por 7 dias
    };

    render() {
        // Se o estado isVisible for falso, não renderiza o componente
        if (!this.state.isVisible) {
            return null;
        }

        return (
            <div>
                <section
                    className="fixed flex items-center justify-between z-50 max-w-4xl p-4 mx-auto bg-white border border-gray-200 dark:bg-gray-900 left-12 bottom-16 dark:shadow-gray-900 shadow-gray-100 md:gap-x-4 dark:border-gray-700 rounded-2xl">
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                        Usamos cookies para melhorar sua experiência de usuário. Ao continuar visitando este
                        site, você concorda com nosso&nbsp;
                        <Link to="/politicadeprivacidade"
                              className="underline transition-colors duration-200 hover:text-blue-500">
                            uso de cookies
                        </Link>
                    </p>

                    <button
                        onClick={this.handleClose}
                        className="flex items-center justify-center text-gray-700 transition-colors duration-300 rounded-full shrink-0 dark:text-gray-200 dark:hover:bg-gray-700 w-7 h-7 focus:outline-none hover:bg-gray-100"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                             className="w-5 h-5">
                            <path
                                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                        </svg>
                    </button>
                </section>
            </div>
        );
    }
}

export default CookieComponent;
