import '../App.css';
import foto01 from '../images/foto01.jpg';
import Rodape from "./Rodape";
import RobotImage from '../images/conversando.png';

import seloSejaFranqueado from '../images/seja-nosso-franqueado.png';
import seloBrasil from '../images/selobrasil.png';
import logoProtege from '../images/logoprotege.png';

import React, { useState } from "react";
import JivoChat from "./JivoChat";
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";

const Franqueado = () => {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "franqueado"
    })

    const [chatUnavailable, setChatUnavailable] = useState(false); // Estado de indisponibilidade

    const openChat = () => {
        try {
            if (window.jivo_api && window.jivo_api.open) {
                window.jivo_api.open();
                console.log("Chat aberto");
            }
        } catch (error) {
            // Exibe a popup de indisponibilidade se ocorrer algum erro
            setChatUnavailable(true);
            console.log("Erro ao tentar abrir o chat: ", error.message);
        }
    };

    const closePopup = () => {
        setChatUnavailable(false); // Fecha a popup
    };

    return (
        <div className="pt-24 md:pt-0">
            <Helmet>
                <title>Seja um Franqueado - Protege Piso</title>
                <meta name="description" content="Descubra as vantagens e benefícios de ser um franqueado Protege Piso na sua região." />

                {/* Open Graph */}
                <meta property="og:title" content="Seja um franqueado - Protege Piso" />
                <meta property="og:description" content="Descubra as vantagens e benefícios de ser um franqueado Protege Piso na sua região." />
                <meta property="og:image" content="hhttps://protegepiso.com.br/wp-content/uploads/elementor/thumbs/logo-contorno-final-3-qr9ktubyh3wykeoagoppheagkansuuyyyeqvz95qtk.png" />
                <meta property="og:url" content="https://protegepiso.com.br/franqueado" />
                <meta property="og:type" content="article" />

                {/* Canonical Tag */}
                <link rel="canonical" href="https://protegepiso.com.br/franqueado" />
            </Helmet>
            <JivoChat /> {/* Carrega o componente JivoChat */}
            <div className='relative h-[300px] md:h-80 flex flex-col md:flex-row font-navBarFont bg-[#17A541]'>
                <div className='flex flex-col justify-center p-4 md:p-8 w-full md:w-[58%] text-white bg-[#17A541] mt-12'>
                    <div className='text-left md:pl-16'>
                        <h3 className='text-xl md:text-2xl mb-4'>
                            Seja um parceiro
                        </h3>
                        <h1 className='text-xl md:text-4xl font-bold mb-4'>
                            Protege Piso
                        </h1>
                        <p className='text-lg md:text-xl'>
                            Trabalhe com o melhor sistema de proteção de pisos para sua obra.
                        </p>
                    </div>
                </div>

                <div className='relative w-full md:w-[42%] bg-cover bg-center mt-12'
                     style={{backgroundImage: `url(${foto01})`}}>
                    <img
                        src={logoProtege}
                        alt='Logo'
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-1/3'
                    />
                </div>
            </div>

            <div className='bg-[#126232] text-white p-4 md:p-8 flex flex-col md:flex-row'>
                <div className='w-full md:w-3/4 font-light font-navBarFont'>
                    <p className='text-lg md:text-xl mb-2'>
                        • Sistema de proteção aprovado para qualquer tipo de piso (área interna, externa e pisos de
                        madeira), sem a utilização de adesivos.
                    </p>
                    <p className='text-lg md:text-xl mb-2'>
                        • Tecnologia exclusiva para suportar grandes impactos.
                    </p>
                    <p className='text-lg md:text-xl mb-2'>
                        • Redução de ruídos na obra.
                    </p>
                    <p className='text-lg md:text-xl mb-2'>
                        • Impecável performance em vedação para líquidos e sólidos.
                    </p>
                    <p className='text-lg md:text-xl mb-2'>
                        • Geometria antiderrapante das placas.
                    </p>
                    <p className='text-lg md:text-xl'>
                        • Produto sustentável, 100% reciclado e reutilizável.
                    </p>
                </div>
                <div className='w-full md:w-1/4 flex flex-col items-center mt-4 md:mt-0'>
                    <img src={seloSejaFranqueado}
                         alt='Imagem 1' className='w-1/2 mb-5'/>
                    <img src={seloBrasil} alt='Imagem 2'
                         className='w-1/2'/>
                </div>
            </div>

            <div className='p-4 md:px-48 mt-12 mb-12 font-navBarFont'>
                <h1 className='text-xl md:text-3xl font-extrabold text-[#126232] text-center mb-12'>
                    Descubra as vantagens e benefícios de ser um franqueado Protege Piso na sua região.
                </h1>
                <div className='flex flex-col md:flex-row justify-center'>
                    <ul className='w-full md:w-1/2 space-y-4 text-left pl-6 list-disc'>
                        <li className='text-base md:text-lg'>
                            Treinamento e capacitação completa de toda a equipe.
                        </li>
                        <li className='text-base md:text-lg'>
                            Software com sistema de operação e gerenciamento de obras.
                        </li>
                        <li className='text-base md:text-lg'>
                            Assessoria de marketing e propaganda padronizada para sites, mídias sociais e artes
                            gráficas.
                        </li>
                        <li className='text-base md:text-lg'>
                            Garantia ilimitada do produto.
                        </li>
                    </ul>

                    <ul className='w-full md:w-1/2 space-y-4 text-left pl-6 list-disc'>
                        <li className='text-base md:text-lg'>
                            Produto pioneiro e patenteado.
                        </li>
                        <li className='text-base md:text-lg'>
                            Participação em feiras e eventos.
                        </li>
                        <li className='text-base md:text-lg'>
                            Baixo custo operacional e alta rentabilidade.
                        </li>
                        <li className='text-base md:text-lg'>
                            Área regional de atuação.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="pt-24 md:pt-0">
                <div className="w-full bg-[#D0CFCF] pb-28 flex flex-col items-center justify-center text-center">
                    <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-6 text-[#126232]">
                        Entre em contato conosco através do nosso Chat.
                    </h2>
                    <p className="text-xl md:text-xl lg:text-xl font-bold mb-6 text-[#529460]">
                        Atendimento das 08:00 às 17:00
                    </p>
                    <button
                        id="openChatBtn"
                        className="bg-[#1068c7] text-white font-semibold py-3 px-10 rounded-full shadow-md hover:bg-[#3e99fa] transition-all duration-300 mb-4 flex items-center justify-center"
                        onClick={openChat}
                    >
                        Abrir Chat
                        <img src={RobotImage} alt="Robô" className="w-8 h-8 ml-2"/>
                    </button>
                    <div>
                        <h4>Obs: Caso clique no botão e o chat não abra, podemos estar sem operadores no momento e portanto com o chat indisponível. <br/>Caso isso ocorra, entre em contato pelos meios de comunicação no rodapé da página.</h4>
                    </div>
                </div>

                {chatUnavailable && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                            <h2 className="text-xl font-bold mb-4">Chat Indisponível</h2>
                            <p>O chat não está disponível no momento. Tente novamente mais tarde.</p>
                            <button
                                className="mt-4 bg-[#1068c7] text-white py-2 px-4 rounded-full"
                                onClick={closePopup}
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                )}



            </div>

            <Rodape />
        </div>
    );
};

export default Franqueado;
